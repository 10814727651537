// Copyright © 2023 Ory Corp
// SPDX-License-Identifier: Apache-2.0

//Updated for creating accout so I can pass in the referrer code 
export const SetUriFlow = (router, id) => {
  // Check that current query flow id does not match requested one - pushing will trigger useEffect if router bound
  if (router?.query?.flow == id) {
    return;
  } 

  router.push(`${router.pathname}?flow=${id}`, undefined, { shallow: true });
};



//For Login
export const SetLoginUriFlow = (router, id) => {
  // Check that current query flow id does not match requested one - pushing will trigger useEffect if router bound
  if (router?.query?.flow == id) {
    return;
  } 

  router.push(`${router.pathname}?flow=${id}`, undefined, { shallow: true });
};